<template>
  <h1 class="text-center">Testimonial</h1>
</template>

<script>
export default {
  name: "Testimonial"
}
</script>

<style scoped>

</style>